import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    *,*::after,*::before{
        margin:0;
        padding: 0;
        box-sizing: border-box;
    }

    body{
        font-family: 'Roboto', sans-serif;
    }

    :root{
        --darkBlue: #11587B;
        --lightBlue: #1D8CC3;
        --lightGreen: #477B11;
        --darkGreen: #2D4D0D;
        --grey: #707070;
        --darkText:#4F4F4F;
        --lightText:#7E7E7E;
    }
    .comming{
        text-align: center;
    color: green;
    font-size: 50px;
    margin: 104px 0px;
    }
    .cards{
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
        transition: 0.3s;
        color:black;
        padding: 13%;
    }
    .cards:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        color:green;
      }
    .cards_about-us{
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
        transition: 0.3s;
        color:black;
        padding: 4%;
        margin-bottom: 4%;
    }
    .cards_about-us:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      }
    /* .cards_about-us h4{
        margin: 3% 11%;
    } */
    .cards_about-us h4{
        margin: 0px 0px 30px 10px;
    }
    .cards_about-us p{
        margin-right: 16%;
        margin-left: 5%;
    }

    .cards_about-us a{
        color: green;
        text-decoration: none!important;
    }
    .center_cards{
        text-align:center;
        margin: 30px 31%
    }
    .text-right{
        text-align:right;
    }
    .d-flex.justify-content-around img{
        max-width: 400px;
        min-height: 279px;
    }
    .d-flex.justify-content-around{
        margin: 100px auto;
    }

    .margin_from{
        margin:0px 10px;
    }
    .form_need  .submit_button{
        border-color: green;
        border-radius: 19px;
        padding: 6px 0px;
        width: 126px;
        margin-top: 17px;
        background: white;
    }
    .form_need  .d-flex{
        float:left;
    }
    .top_footer_button a{
        text-decoration: unset;
        color: white;
        background: #477B11;
        padding: 14px 32px;
        border-radius: 5px;
        margin: 2px;
    }
    .need_button{
        border-color: green;
        border-radius: 19px;
        padding: 6px 0px;
        width: 126px;
        margin-top: 17px;
        background: white;
    }
    .contract{
        text-align: right;
    }
    .arneeded{
        float: right;
        flex-direction: row-reverse;
    }


`

export default GlobalStyle
