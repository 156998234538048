import styled from "styled-components"

export const UpperFooter = styled.div`
  position: relative;
  padding: 50px 0;

  .footer-img {

    filter: brightness(28%);
    display: block;
    inset: 0px;
    margin: 0px;
    max-width: none;
    padding: 0px;
    position: absolute !important;
    width: 100% !important;
    height: 360px !important;
    margin-top: -80px;
    z-index: -4;

  }
 
  h2,
  p {
    position:relative;
    z-index:1
    margin-bottom: 25px;
    color: white;
  }
  a {
    position:relative;
    z-index:1;
    font-size: 0.77rem;
    background-color: var(--lightGreen);
    padding: 1rem 2rem;
    text-align: center;
    color: white;
    border-radius: 5px;
    text-decoration: none;
  }
`
export const MidFooter = styled.div`
  background-color: #3b3c41;
  padding: 25px 0;

  .container {
    display: flex;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    .left-content {
      width: 40%;
      border-right: 1px solid #707070;

      @media (max-width: 992px) {
        width: 100%;
        border-right: unset;
        border-bottom: 1px solid #707070;
      }

      .text {
        width: 80%;
        padding-bottom: 40px;
        border-bottom: 1px solid #707070;

        @media (max-width: 992px) {
          width: 100%;
        }

        .who-we-are {
          color: #1d8cc3;
          font-size: 0.77rem;
        }

        .paragraph-text {
          font-size: 0.77rem;
          color: white;
          padding-bottom: 1rem;
        }

        a {
          background-color: var(--lightGreen);
          padding: 1rem 2rem;
          font-size: 0.77rem;
          border-radius: 5px;
          color: white;
          text-decoration: none;
        }
      }
      .social-media {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 1rem;
        color: white;

        span {
          font-size: 0.77rem;
        }

        a {
          width: 20px;
        }
      }
    }
    .right-content {
      width: 60%;
      display: flex;
      padding-left: 10px;
      gap: 10px;
      justify-content: space-between;

      @media (max-width: 992px) {
        padding: 20px 0;
        width: 100%;
        justify-content: space-between;
      }

      @media (max-width: 578px) {
        flex-direction: column;
      }
    }
  }
`
export const Column = styled.div`
  .head {
    font-size: 0.77rem;
    color: #1d8cc3;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      a {
        color: white !important;
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
`
export const BottomFooter = styled.div`
  background-color: #303030;
  padding: 10px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 20px;
    }

    .links {
      display: flex;
      gap: 5px;
      color: white;

      a {
        color: white ;
        text-decoration: none;
        font-weight: 400;
      }
    }

    .copyright {
      color: white;
    }
  }
`
