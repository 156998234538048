import { useState, useEffect } from "react";
import { collection, query, where, getDocs,orderBy } from "firebase/firestore";
import { db } from "../firebase/config"
const useNav = (type) => {
const [data, setData] = useState([]);

  useEffect(() => {
    const fetchNav = async () => {
        const q = query(collection(db, "navigation"), where("type", "==", type),orderBy('position'));
        const querySnapshot = await getDocs(q);
        let arr = []
        querySnapshot.forEach((doc) => {
            arr.push(doc.data())
        });
        setData(arr);
      }
  
      fetchNav()
  }, [type]);
   
  return [data];
};

export default useNav;