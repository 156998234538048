import React, { useState, useEffect, useContext } from "react"

//components
import {
  Content,
  Links,
  LogoContainer,
  NavContainer,
  RightTools,
  Login,
  Search,
  NavToggler,
} from "./Header.styles"

import Logo from "../../assets/images/logo.svg"
import { Link } from "gatsby"
import UserIcon from "../../assets/icons/user.svg"
import SearchIcon from "../../assets/icons/search.svg"
import { useAuthContext } from "../../hooks/useAuthContext"
import { StateContext } from "../../helpers/StateProvider"

import useNav from "../../hooks/useNavigation"
import Loader from "../../components/Loader"

function Header(props) {
  const [menuState, setMenuState] = useState(false)
  const { language, setLanguage } = useContext(StateContext)
  const arabic = props.arabic
  const { user, authIsReady } = useAuthContext()
  const top = useNav("top")

  return (
    <header>
      {authIsReady && (
        <div
          className={
            props.page == "/add-listing" ||
            props.page == "/login" ||
            props.page == "/register"
              ? "d-none"
              : "container"
          }
        >
          <Content>
            <LogoContainer>
              <img src={Logo} alt="Logo" />
              <p>
                DOT <span>VIP</span>
              </p>
            </LogoContainer>
            <NavContainer isOpen={menuState}>
              <Links>
                <ul>
                  {top &&
                    top[0].map(row => {
                      const {
                        label = row.label,
                        link = row.link,
                        label_ar = row.label_ar,
                      } = row
                      return (
                        <li key={label}>
                          <Link
                            to={link}
                            activeClassName="active"
                            onClick={() => setMenuState(!menuState)}
                          >
                            {arabic == false ? label : label_ar}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </Links>
              <RightTools>
                <Login>
                  {user && (
                    <Link
                      to="/dashboard"
                      onClick={() => setMenuState(!menuState)}
                    >
                      {arabic == false ? "Dashboard" : "لوحة التحكم"}

                      <img src={UserIcon} alt="user icon" />
                    </Link>
                  )}
                  {!user && (
                    <Link to="/login" onClick={() => setMenuState(!menuState)}>
                      {arabic == false ? "Stay Connected" : "إبقى على تواصل"}
                      <img src={UserIcon} alt="user icon" />
                    </Link>
                  )}
                </Login>
                <Search>
                  <img src={SearchIcon} alt="Search icon" />
                </Search>
              </RightTools>
              <Links>
                <ul>
                  <li>
                    <Link
                      to=""
                      activeClassName={language == false ? "active" : ""}
                      onClick={() => {
                        setLanguage(false)
                        setMenuState(!menuState)
                      }}
                    >
                      {arabic == false ? "Eng" : "إنجليزي"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=""
                      activeClassName={language == true ? "active" : ""}
                      onClick={() => {
                        setLanguage(true)
                        setMenuState(!menuState)
                      }}
                    >
                      {arabic == false ? "Ar" : "عربي"}
                    </Link>
                  </li>
                </ul>
              </Links>
            </NavContainer>
            <NavToggler
              onClick={() => setMenuState(!menuState)}
              isOpen={menuState}
            >
              <div className="top"></div>
              <div className="mid"></div>
              <div className="bottom"></div>
            </NavToggler>
          </Content>
        </div>
      )}
    </header>
  )
}

export default Header
