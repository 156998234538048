/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

// You can delete this file if you're not using it
//import "@popperjs/core/dist/umd/popper.min"
//import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap/dist/js/bootstrap.min"
import "./src/assets/terms.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import React from "react"
import { AuthContextProvider } from "./src/context/AuthContext"
import Layout from "./src/components/layout"
import { StateProvider } from  './src/helpers/StateProvider';



export const wrapPageElement = ({ props, element }) => {
  return <AuthContextProvider {...props}><StateProvider><Layout>{element}</Layout></StateProvider></AuthContextProvider>
}