//import { initializeApp } from "firebase/app"
import app from 'gatsby-plugin-firebase-v9.0'
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"

/*const firebaseConfig = {
  apiKey: "AIzaSyB3evkZwcjf3J0fT5biJiJ9AMUAfh6fLO0",
  authDomain: "opddev-51cfb.firebaseapp.com",
  projectId: "opddev-51cfb",
  storageBucket: "opddev-51cfb.appspot.com",
  messagingSenderId: "373784165590",
  appId: "1:373784165590:web:d661b0f08e90a4fe15dd77",
  measurementId: "G-7MDHWJN4KV",
}

//initializeApp(firebaseConfig)
*/
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export { db, auth, storage }
