import React, {
  useRef,
  useState,
  createContext,
  useEffect,
  useContext,
} from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { UpperFooter, MidFooter, Column, BottomFooter } from "./Footer.styles"
import "bootstrap-icons/font/bootstrap-icons.css"
import useNav from "../../hooks/useNavigation"
import { StateContext } from "../../helpers/StateProvider"

import { Links } from "../Header/Header.styles"

const Footer = props => {
  const widgetData = props.dataUpper
  const widgetData2 = props.whoWeAre
  const arabic = props.arabic
  const browse = useNav("browse")
  const explore = useNav("explore")
  const topcategories = useNav("topcategories")
  const ourservices = useNav("ourservices")
  const followus = useNav("followus")
  const [loading, setLoading] = useState(true)
  const [menuState, setMenuState] = useState()
  useEffect(() => {
    setLoading(false)
  }, [widgetData])
  useEffect(() => {
    setLoading(false)
  }, [widgetData2])

  const { language, setLanguage } = useContext(StateContext)

  return (
    <footer>
      <UpperFooter>
        <img
          src={widgetData && widgetData.image}
          alt="Villa image"
          className="footer-img"
        ></img>

        <div className={arabic == false ? "container" : "container text-end"}>
          <h2>
            {" "}
            {widgetData && arabic == false
              ? widgetData.title
              : widgetData?.title_ar}
          </h2>
          <p>
            {widgetData && arabic == false
              ? "If you are looking for a place to market your property, you have found it. We are 100% committed to supporting and marketing your real estate ads digitally for local and regional residents and website visitors to find online."
              : "إذا كنت تبحث عن مكان لتسويق عقارك فلقد وجدته. نحن ملتزمون بنسبة 100٪ بدعم الإعلانات العقارية الخاصة بك وتسويقها رقميا ليعثر عليها السكان المحليين والإقليميين وزوار الموقع عبر الإنترنت"}
          </p>
          <Link to={widgetData && widgetData.link}>
            {widgetData && arabic == false
              ? widgetData.link_text
              : widgetData?.link_text_ar}
          </Link>
        </div>
      </UpperFooter>
      <MidFooter>
        <div className="container">
          <div className="left-content">
            <div className={arabic == false ? "text" : "text text-end"}>
              <p className="who-we-are">
                {widgetData2 && arabic == false
                  ? widgetData2.title
                  : widgetData2?.title_ar}
              </p>
              <p className="paragraph-text">
                {widgetData2 && arabic == false
                  ? widgetData2.description
                  : widgetData2?.description_ar}
              </p>
              <Link to={widgetData2 && widgetData2.link}>
                {widgetData2 && arabic == false
                  ? widgetData2.link_text
                  : widgetData2?.link_text_ar}
              </Link>
            </div>
            <div className="social-media">
              <span>{arabic == true ? "اتبعنا" : "FOLLOW US ON"}</span>
              {followus &&
                followus[0].map(row => {
                  const { label = row.label, link = row.link } = row
                  return (
                    <a href={link} key={label}>
                      {/* <img src={FacebookIcon} alt="Facebook icon" /> */}
                      <i
                        className={"bi bi-" + label}
                        style={{ color: "green" }}
                      ></i>
                    </a>
                  )
                })}
            </div>
            <div className="social-media">
              <span>DOT VIP</span>
              <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                From
              </span>
              <span>Online Property Deals (OPD)</span>
            </div>
            <div className="top_footer_button">
              <Link to="#___gatsby">
                {arabic === false ? "to the top" : "ارجع الى أعلى الصفحة"}
              </Link>
            </div>
          </div>

          <div className="right-content">
            <Column>
              <div className={arabic == false ? "head" : "head text-end"}>
                {arabic == false ? "BROWSE" : "تصفح"}
              </div>
              <ul className={arabic == false ? "" : "text-end"}>
                {browse &&
                  browse[0].map(row => {
                    const {
                      label = row.label,
                      link = row.link,
                      label_ar = row.label_ar,
                    } = row
                    return (
                      <li key={label}>
                        <Link key={label} to={link}>
                          {arabic == false ? label : label_ar}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </Column>
            <Column>
              <div className={arabic == false ? "head" : "head text-end"}>
                {arabic == false ? "EXPLORE" : "استكشف"}
              </div>
              <ul className={arabic == false ? "" : "text-end"}>
                {explore &&
                  explore[0].map(row => {
                    const {
                      label = row.label,
                      link = row.link,
                      label_ar = row.label_ar,
                    } = row
                    return (
                      <li>
                        <Link to={link} key={label}>
                          {arabic == false ? label : label_ar}
                        </Link>
                      </li>
                    )
                  })}
                {/* <li>
                  <Link to="/">FIND A RESIDENT</Link>
                </li>
                <li>
                  <Link to="/">FIND AN OFFICE</Link>
                </li>
                <li>
                  <Link to="/">BEST PROPERTIES IN BEIRUT</Link>
                </li> */}
              </ul>
            </Column>
            <Column>
              <div className={arabic == false ? "head" : "head text-end"}>
                {arabic == false ? "OUR SERVICES" : "خدماتنا"}
              </div>
              <ul className={arabic == false ? "" : "text-end"}>
                {ourservices &&
                  ourservices[0].map(row => {
                    const {
                      label = row.label,
                      link = row.link,
                      label_ar = row?.label_ar,
                    } = row
                    return (
                      <li>
                        <Link to={link} key={label}>
                          {arabic == false ? label : label_ar}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </Column>
            <Column>
              <div className={arabic == false ? "head" : "head text-end"}>
                {arabic == false ? "MY PROPERTIES WISH LIST" : "قائمة أمنياتي للعقارات"}
              </div>
              <ul className={arabic == false ? "" : "text-end"}>
                      <li>
                          {arabic == false 
                          ? <a target="_blank" href="https://new.propertypro.vip/myhomekey">MY HOME KEY</a> 
                          : <a target="_blank" href="https://new.propertypro.vip/ar/myhomekey">مفتاح منزلي</a> 
                          }
                      </li>

                      <li>
                          {arabic == false 
                          ? <a target="_blank" href="https://new.propertypro.vip/myland">MY LAND TITLE DEED</a> 
                          : <a target="_blank" href="https://new.propertypro.vip/ar/myland">سند ملكية أرضي</a> 
                          }
                      </li>

                      <li>
                          {arabic == false 
                          ? <a target="_blank" href="https://new.propertypro.vip/mybusiness">MY BUSINESS LOCAL KEY</a> 
                          : <a target="_blank" href="https://new.propertypro.vip/ar/mybusiness">مفتاح موقع عملي</a> 
                          }
                      </li>
                      
              </ul>
            </Column>
            <Column>
              <div className={arabic == false ? "head" : "head text-end"}>
                {arabic == false ? "CONSULT DOT VIP EXPERTS" : "استشر خبراء DOT VIP"}
              </div>
              <ul className={arabic == false ? "" : "text-end"}>
                      <li>
                      <Link to="/sworn-topographer">
                          {arabic == false 
                          ? "A SWORN TOPOGRAPHER" 
                          : "طوبوغرافي محلف"
                          }
                        </Link>
                      </li>

                      <li>
                        <Link to="/appraisal-specialist">
                          {arabic == false 
                          ? "A SWORN PROPERTY APPRAISAL SPECIALIST"
                          : "أخصائي تقييم الممتلكات المحلف" 
                          }
                          </Link>
                      </li>

                      <li>
                        <Link to="/broker">
                          {arabic == false 
                          ? "A BROKER" 
                          : "وسيط"
                          }
                         </Link>
                      </li>

                      <li>
                        <Link to ="/lawyer">
                        {arabic == false 
                          ? "A LAWYER" 
                          : "محامي"
                          }
                        </Link>
                      </li>

                      <li>
                        <Link to="/sworn-accountant">
                          {arabic == false 
                            ? "A SWORN ACCOUNTANT"
                            : "محاسب محلف"
                           }
                        </Link>
                          
                      </li>


                      <li>
                        <Link to="/graphic-designer">
                        {arabic == false 
                          ? "A GRAPHIC DESIGNER"
                          : "مصمم جرافيك" 
                          }
                        </Link>
                      </li>
                      
              </ul>
            </Column>
          </div>
        </div>
      </MidFooter>
      <BottomFooter>
        <div className="container">
          <div className="links">
            <Link to="/">
              {arabic == false ? "PRIVACY & POLICY" : "سياسة الخصوصية"}
            </Link>
            .
            <Link to="/terms-and-conditions">
              {arabic == false
                ? "TERMS & CONDITIONS"
                : "إخلاء المسؤولية وشروط الاستخدام"}
            </Link>
          </div>
          <div className="copyright">
            {arabic == false
              ? " Copyright 2022 by Online Property Deals"
              : "أعلى الفئات حقوق الطبع والنشر 2022 بواسطة Online Property Deals;"}
          </div>

          <Links>
            <ul>
              <li>
                <Link
                  to=""
                  activeClassName={language == false ? "active" : ""}
                  onClick={() => {
                    setLanguage(false)
                    setMenuState(!menuState)
                  }}
                >
                  {arabic == false ? "Eng" : "إنجليزي"}
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  activeClassName={language == true ? "active" : ""}
                  onClick={() => {
                    setLanguage(true)
                    setMenuState(!menuState)
                  }}
                >
                  {arabic == false ? "Ar" : "عربي"}
                </Link>
              </li>
            </ul>
          </Links>
        </div>
      </BottomFooter>
    </footer>
  )
}

export default Footer
