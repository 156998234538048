import React, { useState } from "react"

export const StateContext = React.createContext()

export const StateProvider = ({ children }) => {
  const [language, setLanguage] = useState(false)
  const [messageerror, setMessageerror] = useState("")
  const [title, setTitle] = useState()
  const [content, setContent] = useState()
  return (
    <StateContext.Provider
      value={{
        language,
        setLanguage,
        messageerror,
        setMessageerror,
        title,
        setTitle,
        content,
        setContent,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
