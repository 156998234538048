import React, { useState, useEffect, useContext } from "react"

import GlobalStyle from "../../assets/globalStyles"

//components
import Footer from "../Footer"
import Header from "../Header"
import Loader from "../Loader"
import { Link, graphql } from "gatsby"
import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { StateContext } from "../../helpers/StateProvider"

function Layout(props) {
  const [loading, setLoading] = useState(true)
  const { language, setLanguage } = useContext(StateContext)
  const { user, authIsReady } = useAuthContext()
  const [userData, setUserData] = useState([])

  useEffect(() => {
    setInterval(function () {
      setLoading(false)
    }, 50)
  }, [])

  const pageUri = props.children?.props?.uri
  const topEdges = props.children?.props?.data?.top?.edges || []

  useEffect(() => {
    if (user) {
      props.children.props.data.allUsers?.edges.map(users => {
        if (
          user.reloadUserInfo &&
          user.reloadUserInfo.localId == users.node.id
        ) {
          setLanguage(users.node.arabic_language)
        }
      })
    }
  }, [user])

  return (
    <div className={language == false ? "" : "ar"}>
      <GlobalStyle />
      <Header page={pageUri} nav={topEdges} arabic={language} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <main>{props.children}</main>
          <Footer
            arabic={language}
            dataUpper={props?.children.props.data.widget7}
            whoWeAre={props?.children.props.data.widget8}
            nav1={props?.children.props.data.browse?.edges}
            nav2={props?.children.props.data.explore?.edges}
            nav3={props?.children.props.data.ourservices?.edges}
            nav4={props?.children.props.data.topcategories?.edges}
            nav5={props?.children.props.data.followus?.edges}
          />
        </>
      )}
    </div>
  )
}

export default Layout
