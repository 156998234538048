import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  
  img {
    width: 50px;
    margin-right: 10px;
  }

  p {
    margin: 0;
    font-size: 1.2rem;
    color: var(--darkBlue);
    font-weight: bold;

    span {
      font-weight: normal;
      color: var(--darkText);
    }
  }
`

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 1200px) {
    gap: 20px;
  }

  @media (max-width: 992px) {
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 50px 0;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    overflow: scroll;

    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(100%)"};
    transition: 0.3s ease-in;
    z-index: 999;
  }
`

export const Links = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    list-style: none;
    margin: 0;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 50px;
    }

    li {
      color: var(--darkText);
      font-weight: 600;

      a {
        color: inherit;
        text-decoration: none;
        font-weight: 500;
      }

      a.active {
        color: var(--lightGreen);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 58%;
          height: 3px;
          background-color: var(--lightGreen);
          top: -6px;
          left: 0;
        }
      }

      a.boxed {
        font-size: 0.77rem;
        padding: 10px 20px;
        color: var(--lightGreen) !important;
        border: 1px solid var(--lightGreen);
        border-radius: 3px;
        font-weight: 500;
      }
    }
  }
`

export const RightTools = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    width: 100%;
    padding: 0 10px;
  }
`

export const Login = styled.div`
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    color: var(--darkText);
    text-decoration: none;
    font-weight: 500;
  }
`

export const Search = styled.button`
  visibility: hidden; 
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--lightGreen);
  border: none;
`

export const NavToggler = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: var(--lightGreen);
  flex-direction: column;
  gap: 5px;
  border-radius: 50%;
  display: none;
  z-index: 1000;
  overflow: hidden;
  position:relative;
  right:${({ isOpen }) =>
  isOpen ? "50%" : "0"};;

  .top,
  .mid,
  .bottom {
    height: 2px;
    width: 27px;
    background-color: white;
    transform-origin: 0;
    transition: 0.2s ease-in;
  }

  @media (max-width: 992px) {
  
    display: flex;

    .top {
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(2px)rotateZ(45deg)translateY(-3px)" : "none"};
    }

    .bottom {
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(2px)rotateZ(-45deg)translateY(3px)" : "none"};
    }

    .mid {
      ${({ isOpen }) => (isOpen ? "transform:translateX(200%)" : "none")};
    }

    &:focus {
      outline: none;
    }
  }
`

export const LinkToHome = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lightGreen);
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;

    svg {
      width: 20px;
    }

    &:hover {
      color: var(--lightGreen);
    }
  }
`
