import { doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../firebase/config"

export const useDocument = (collection, id) => {
  const [document, setDocument] = useState({})
  const [error, setError] = useState(null)
  // fetch realtime data
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, collection, id),
      doc => {
        if (doc) {
          setDocument({ id: doc.id, data: doc.data() })
        } else {
          setError("No data could be found")
        }
      },
      err => {
        setError("Failed to get document")
      }
    )
    return () => unsubscribe()
  }, [collection, id])

  return new Promise(resolve => {
    resolve(document, error)
  })
}
